import React from 'react';
import { withRouter, Link } from "react-router-dom";

class Home extends React.Component {
  constructor(props) {
    super(props);

    const { params } = this.props.match;
    
    console.log(params);

    this.state = {
      events: null
    };
  }

  componentDidMount() {
    fetch('/api/index.php?type=events')
    .then(response => {
      console.log(response);
        return response.json();
    })
    .then(({ events }) => {
      console.log(events);
        this.setState({
            events: events
        });
    }).catch(e => {
        console.log(e);
    });
  }

  render() {
    if (!this.state.events) {
      return null;
    }

    const { events } = this.state;

    return (
        <div className="content">
            <div className="heading">Events</div>
            <ul className="eventList">
                {
                  events.reduce((acc, event) => {
                    if(!event.date || (new Date() > new Date(event.date))) {
                        let link = "/results/event/" +event.id;
                        acc.push(
                            <li>
                                <Link to={link}>{event.name}</Link>
                            </li>
                        );
                    }

                    return acc;
                  }, [])
                }
            </ul>
            <Link to="/register" className="registerLink">Register for the 2025 Pinewood Derby</Link>
        </div>
    );
  }
}

export default withRouter(Home);
